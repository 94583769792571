import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';

import { ErrorAlert } from '@components/Alerts/ErrorAlert';
import { BulkErrorBoundary } from '@components/Alerts/ErrorBoundaryFallback';
import { Button } from '@components/Button';
import { LoginModal } from '@components/LoginForm/LoginModal';
import { Paragraph } from '@components/Typography/Paragraph/Paragraph';
import { useCustomer } from '@hooks/customer/useCustomer';
import { useAddItem } from '@hooks/wishlist/useAddItem';
import { useRemoveItem } from '@hooks/wishlist/useRemoveItem';
import { useWishlist } from '@hooks/wishlist/useWishlist';
import { isStackSku } from '@lib/productHelpers';
import { mergeStyles } from '@lib/styles';
import { logBreadcrumb } from '@lib/utils';

function FavouriteIcon({ fill }: { fill: boolean }): ReactElement {
  return fill ? (
    <AiFillHeart className="text-pink" size="1.5rem" />
  ) : (
    <AiOutlineHeart size="1.5rem" />
  );
}

interface Props {
  productId: number;
  sku: string;
  fullWidth?: boolean;
  className?: string;
}

export function AddToFavourites({
  productId,
  sku,
  fullWidth = false,
  className,
}: Props): ReactElement {
  const addItem = useAddItem();
  const removeItem = useRemoveItem();
  const { data } = useWishlist();
  const { customer } = useCustomer();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ReactNode>();
  const [isFavourited, setIsFavourited] = useState<boolean>(false);

  const itemInWishlist = data?.items?.find(
    (item) => item.product_id === productId
  );

  useEffect(() => {
    // customer must be logged in and item is in wishlist
    setIsFavourited(!!itemInWishlist && !!customer);
  }, [customer, itemInWishlist]);

  const handleWishlistChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!customer) {
      setShowModal(true);
      setLoading(false);
      return;
    }

    if (itemInWishlist) {
      logBreadcrumb({
        category: 'Wishlist',
        message: `Tried to remove item from wishlist`,
        level: 'info',
        data: { id: itemInWishlist.id! },
      });
      const { error } = await removeItem({
        itemId: itemInWishlist.id!,
        wishlistId: data?.id!,
      });
      if (error) {
        setError(true);
        setErrorMessage(
          <Paragraph>
            Unable to remove item from your wishlist: {error}
          </Paragraph>
        );
      }
    } else {
      logBreadcrumb({
        category: 'Wishlist',
        message: `Tried to add item to wishlist`,
        level: 'info',
        data: { id: productId },
      });
      const { error } = await addItem({
        productId,
      });
      if (error) {
        setError(true);
        setErrorMessage(
          <Paragraph>Unable to add to your wishlist: {error}</Paragraph>
        );
      }
    }
    setLoading(false);
  };

  // do not allow stack to be wishlisted
  if (isStackSku(sku)) {
    return <></>;
  }

  const ariaLabel = isFavourited
    ? 'Remove product from favourites'
    : 'Add to favourites';

  return (
    <BulkErrorBoundary location="AddToFavourites">
      <ErrorAlert show={error} setShow={setError}>
        {errorMessage}
      </ErrorAlert>
      {fullWidth ? (
        // fullwidth button used in individual product pages
        <Button
          onClick={handleWishlistChange}
          aria-label={`${ariaLabel} button`}
          className={mergeStyles(className ?? '', `fullwidth-product-atf`)}
          type="submit"
          buttonStyle="secondary"
          icon={<FavouriteIcon fill={isFavourited} />}
        >
          {isFavourited ? 'Remove from' : 'Add to'} favourites
        </Button>
      ) : (
        <div
          className={`heart-product-atf ${loading ? 'animate-pulse' : ''} inline-block duration-500 hover:scale-120 hover:duration-200`}
        >
          <Button
            aria-label={ariaLabel}
            onClick={handleWishlistChange}
            buttonStyle="tertiary"
            icon={<FavouriteIcon fill={isFavourited} />}
          />
        </div>
      )}
      <LoginModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle="Sign in to add favourite products"
        modalIntent="to start building your favourite product list."
      />
    </BulkErrorBoundary>
  );
}
